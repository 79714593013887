<template>
  <v-card light>
    <v-container fluid v-if="team.isDeleted">
      <v-row dense>
        <v-col class="text-center" >
          <v-card color="grey lighten-4">
            <v-card-title>
              <h3>{{team.name}} is no longer registered for {{tournament.name}}</h3>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else-if="tournament.locked && !(user && user.vbl)">
      <v-row dense>
        <v-col class="text-center" >
          <v-card color="grey lighten-4">
            <v-card-title>
              <h3>{{tournament.name}} has been locked for editing</h3>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- Change Request -->
    <v-container fluid v-else-if="((isPastTime || tournament.bidProgram) && !access.td && !currentDivision.lineups)">
      <v-row dense>
        <v-col cols="12">
          <v-card color="grey lighten-4">
            <v-container>
              <v-row dense>
                <v-col cols="12">
                  <h3 style="width: 100%">
                    Partner Change
                  </h3>
                </v-col>
                <v-col cols="12" v-if="!tournament.bidProgram">
                  You can only make changes up until registration closes. Any changes now, need to be approved by the tournament director.
                  Please enter your detailed request below and we will notify the tournament director.
                </v-col>
                <v-col cols="12" v-else>
                  Any changes to a bid tournament registration, need to be approved by the tournament director.
                  Please enter your detailed request below and we will notify the tournament director.
                </v-col>
                <change-request
                  ref="changeRequest"
                  :teamId="team.id"
                  :busy="busy"
                  type="Partner Change"
                  @valid-change="onChangeRequestValidChange"
                ></change-request>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense justify="center">
        <v-col cols="12" class="text-center">
          <v-btn
            color="color3 color3Text--text"
            class="mr-2"
            @click="onSubmitRequest"
            :loading="busy"
            :disabled="busy || !validRequest"
          >Submit request</v-btn>
          <v-btn
            @click="reset()"
            :disabled="busy || !validRequest"
          >Cancel</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-else>
      <!-- CHANGE DROP ADD BUTTONS-->
      <v-row dense>
        <v-expand-transition>
          <v-col cols="12" class="text-h6" v-if="!mode">
            What would you like to do?
          </v-col>
        </v-expand-transition>
        <v-col cols="12">
          <v-btn
            :color="mode === 'change' ? 'color3 color3Text--text' : 'color3Text color3--text'"
            @click.stop="setMode('change')"
            :fab="$vuetify.breakpoint.xsOnly && mode && mode !== 'change'"
            :small="$vuetify.breakpoint.xsOnly && mode && mode !== 'change'"
            :disabled="!canChange"
            :block="$vuetify.breakpoint.xsOnly && !mode"
            class="mr-2"
          >
            <v-icon :class="{'pr-2': $vuetify.breakpoint.smAndUp || !mode || mode === 'change'}">fas fa-user-edit</v-icon>
            {{$vuetify.breakpoint.smAndUp || !mode || mode === 'change' ? `Change ${doubles ? 'Partners' : 'a player'}` : ''}}
          </v-btn>
          <v-btn
            v-if="canAdd"
            :color="mode === 'add' ? 'color3 color3Text--text' : 'color3Text color3--text'"
            @click.stop="setMode('add')"
            :fab="$vuetify.breakpoint.xsOnly && mode && mode !== 'add'"
            :small="$vuetify.breakpoint.xsOnly && mode && mode !== 'add'"
            :block="$vuetify.breakpoint.xsOnly && !mode"
            class="mr-2"
            :class="{ 'mt-3': $vuetify.breakpoint.xsOnly && !mode }"
          >
            <v-icon :class="{'pr-2': $vuetify.breakpoint.smAndUp || !mode || mode === 'add'}">fas fa-user-plus</v-icon>
            {{$vuetify.breakpoint.smAndUp || !mode || mode === 'add' ? this.access.joinOnly ? 'Join Team' : 'Add a player' : ''}}
          </v-btn>
          <v-btn
            v-if="canDrop"
            :color="mode === 'drop' ? 'color3 color3Text--text' : 'color3Text color3--text'"
            @click.stop="setMode('drop')"
            :fab="$vuetify.breakpoint.xsOnly && mode && mode!== 'drop'"
            :small="$vuetify.breakpoint.xsOnly && mode && mode!== 'drop'"
            :block="$vuetify.breakpoint.xsOnly && !mode"
            :class="{ 'mt-3': $vuetify.breakpoint.xsOnly && !mode }"
          >
            <v-icon :class="{'pr-2': $vuetify.breakpoint.smAndUp || !mode || mode === 'drop'}">fas fa-user-minus</v-icon>
            {{$vuetify.breakpoint.smAndUp || !mode || mode === 'drop' ? 'Drop a player' : ''}}
          </v-btn>
        </v-col>
      </v-row>
      <!-- WINDOWS-->
      <v-expand-transition>
        <v-row dense v-if="mode">
          <v-col cols="12">
            <v-window v-model="window" touchless :key="mode">
              <v-window-item v-for="step in steps" :key="step.key" :value="step.value">
                <v-card color="grey lighten-4">
                  <!-- PRICE WARNING -->
                  <v-card-text v-if="step.type === 'price'">
                    <h3>{{step.title}}</h3>
                    <v-alert type="warning" :value="true" prominent>
                      <span class="black--text">Registration price is per player</span>
                    </v-alert>
                    <h3>In order to add a player, you will have to pay the current registration price of {{entryFee.display}}</h3>
                    <v-expand-transition>
                      <refund-policy-display
                        v-if="registration.division && registration.division.refundPolicyId"
                        :refundPolicyId="registration.division.refundPolicyId"
                        :active="step.type === 'review'"
                        @agree-change="refundAgreed = $event"
                      >
                      </refund-policy-display>
                    </v-expand-transition>
                  </v-card-text>
                  <!-- PLAYER DROP -->
                  <v-card-text v-if="step.type === 'drop'">
                    <h3>{{step.title}}</h3>
                    <v-radio-group v-model="dropId">
                      <v-radio
                        color="color3"
                        v-for="(player, i) in team.players"
                        :label="player.name"
                        :value="player.id"
                        :key="i"
                      ></v-radio>
                    </v-radio-group>
                  </v-card-text>
                  <!-- NEW PLAYER -->
                  <v-card-text v-if="step.type === 'new'">
                    <h3>{{step.title}}</h3>
                    <new-registration-fields
                      ref="regFields"
                      :adminQuickAdd="userIsAdmin"
                      :tournament="tournament"
                      :registration="registration"
                      :player="player"
                      :playerI="mode === 'add' ? team.players.length + 1 : team.players.length"
                      :mode="'change'"
                      :xPlayers="team && team.players ? team.players.map(m => m.playerProfileId) : []"
                    ></new-registration-fields>
                  </v-card-text>
                  <!-- CONFIRM -->
                  <v-card-text v-if="step.type === 'review'">
                    <h3>{{step.title}}</h3>

                    <v-card v-if="dropPlayer">
                      <v-toolbar dense color="color2 color2Text--text">
                        <v-toolbar-title>
                          Drop Player
                        </v-toolbar-title>
                      </v-toolbar>
                      <v-container fluid>
                        <v-row dense>
                          <v-col cols="12" v-if="$vuetify.breakpoint.xsOnly">
                            <strong>Name: </strong>{{dropPlayer.name}}
                          </v-col>
                          <template v-else>
                            <v-col class="text-right" cols="4" sm="3" md="2" >
                              <strong>Name:</strong>
                            </v-col>
                            <v-col cols="8" sm="9" md="10">
                              {{dropPlayer.name}}
                            </v-col>
                          </template>
                        </v-row>
                      </v-container>
                    </v-card>

                    <registration-fields-review
                      v-if="registration.division && mode !== 'drop'"
                      :fields="registration.division.registrationFields.fields"
                      :requiredFields="registration.division.registrationFields.requiredFields"
                      :player="player"
                      :sanctioningBodyId="registration.division.sanctioningBodyId"
                      :title="'Add Player'"
                      :tournament="tournament"
                      :registration="registration"
                      :i="mode === 'add' ? team.players.length + 1 : team.players.length"
                    >
                    </registration-fields-review>
                    <div class="d-flex justify-end">
                      <v-checkbox
                        label="Add To Cart"
                        v-model="cartAdd"
                        v-if="userIsAdmin && mode === 'add' && !entryFee.perTeam"
                        color="success"
                        class="shrink"
                      ></v-checkbox>
                    </div>
                  </v-card-text>
                </v-card>
              </v-window-item>
            </v-window>
          </v-col>
          <!-- BUTTONS -->
          <v-col cols="6">
            <!-- LEFT FAB -->
            <v-fab-transition>
              <v-btn
                v-if="currentStep && currentStep.lFab && currentStep.lFab.show"
                :color="currentStep.lFab.color"
                @click.stop="currentStep.lFab.click"
                fab
              >
                <v-icon>{{currentStep.lFab.icon}}</v-icon>
              </v-btn>
            </v-fab-transition>
          </v-col>
          <v-col class="text-right" cols="6" >
            <!-- RIGHT FAB -->
            <v-fab-transition>
              <v-btn
                v-if="currentStep && currentStep.rFab"
                :color="currentStep.rFab.color"
                :disabled="!currentStep.valid"
                @click.stop="currentStep.rFab.click"
                :loading="saving"
                fab
              >
                <v-icon>{{currentStep.rFab.icon}}</v-icon>
              </v-btn>
            </v-fab-transition>
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-container>
    <!-- ADDED TO CART DIALOG -->
    <v-dialog v-model="cartDialog" max-width="500">
      <v-card>
        <v-card-title class="title justify-center">
          Your registration has been added to the cart!
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn color="color3 color3Text--text" class="mr-2" :to="{name: 'checkout'}">Check Out Now</v-btn>
          <v-btn color="color3 color3Text--text" @click.stop="setMode('add'); cartDialog=false">Add Another</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>
import RegistrationFieldsReview from '@/components/Tournament/Registration/RegistrationFieldsReview.vue'
import Player from '@/classes/PlayerProfile'
import ChangeRequest from './ChangeRequest.vue'
import NewRegistrationFields from '@/components/Tournament/Registration/New/NewRegistrationFields.vue'
import { mapGetters } from 'vuex'
import CartItem from '@/classes/CartItem'
import * as actions from '@/store/ActionTypes'
import moment from 'moment'
const RefundPolicyDisplay = () => import('@/components/Cart/RefundPolicyDisplay.vue')

export default {
  props: ['team', 'tournament', 'currentDivision', 'active', 'access'],
  data () {
    return {
      currentStepOg: 1,
      saving: false,
      dropId: null,
      newPlayer: new Player(),
      busy: false,
      validRequest: false,
      mode: null,
      window: 0,
      registration: this.currentDivision ? this.tournament.newRegistration(this.currentDivision.id) : null,
      cartAdd: false,
      cartDialog: false,
      refundAgreed: false
    }
  },
  computed: {
    ...mapGetters(['user', 'getTeamToken']),
    isPastTime () {
      const a = this.tournament.dtRosterCutoff && moment().isAfter(this.tournament.dtRosterCutoff)
      const b = this.currentDivision.props.includes('noSwitchy')
      const c = this.tournament.props.includes('noSwitchy')
      if (b || c) {
        return !this.currentDivision.regOpen
      }
      return a
    },
    teamToken () {
      return this.getTeamToken(this.team.id, this.$route)
    },
    refundAgree () {
      if (!this.registration.division.refundPolicyId) return true
      return this.refundAgreed
    },
    doubles () {
      return this.currentDivision && this.currentDivision.numOfPlayers === 2
    },
    entryFee () {
      return this.currentDivision && this.currentDivision.currentEntryFee
    },
    player () {
      return this.registration && this.registration.players[0]
    },
    canAdd () {
      return this.currentDivision && this.team.players.length < this.currentDivision.numAllowedOnRoster && !this.blockAdd
    },
    blockAdd () {
      return !this.userIsAdmin && this.team.waitlist && !this.hasTBD
    },
    canDrop () {
      return this.canChange && ((this.currentDivision && (this.team.players.length > this.currentDivision.numOfPlayers || (this.team.players.length > 1 && this.tournament.props.includes('allowTbd'))) && (this.userIsAdmin || this.entryFee.perTeam)) || (this.user && this.user.vbl))
    },
    canChange () {
      return this.team.players.length > 0 && this.access && (this.access.admin || this.access.roster)
    },
    hasTBD () {
      return this.currentDivision && this.team.players.length < this.currentDivision.numOfPlayers && !this.team.tags.includes('charge2Add')
    },
    useCart () {
      return this.mode !== 'add' || this.entryFee.perTeam || this.hasTBD ? false : this.userIsAdmin ? this.cartAdd : true
    },
    steps () {
      let s = 1
      let steps = [
        {
          value: s++,
          type: 'price',
          key: 'price',
          title: 'Additional Players',
          valid: this.refundAgree,
          skip: !(this.mode === 'add' && !this.entryFee.perTeam && !this.hasTBD),
          lFab: null,
          rFab: {
            icon: 'fas fa-caret-right',
            color: 'color3 color3Text--text',
            click: this.onNextClick
          }
        },
        {
          value: s++,
          type: 'drop',
          key: 'drop',
          title: 'Which teammate is being dropped from the team?',
          valid: !!this.dropId,
          skip: this.mode === 'add',
          lFab: null,
          rFab: {
            icon: 'fas fa-caret-right',
            color: 'color3 color3Text--text',
            click: this.onNextClick
          }
        },
        {
          value: s++,
          type: 'new',
          key: 'new',
          title: 'Who is the new player?',
          valid: this.player.valid,
          skip: this.mode === 'drop',
          lFab: {
            show: this.mode !== 'add',
            icon: 'fas fa-caret-left',
            color: 'color3Text color3--text',
            click: this.onBackClick
          },
          rFab: {
            icon: 'fas fa-caret-right',
            color: 'color3 color3Text--text',
            click: this.onNextClick
          }
        },
        {
          value: s++,
          type: 'review',
          key: 'review',
          title: 'Confirm Changes',
          valid: true,
          skip: false,
          lFab: {
            show: true,
            icon: 'fas fa-caret-left',
            color: 'color3Text color3--text',
            click: this.onBackClick
          },
          rFab: {
            icon: !this.useCart ? 'fas fa-save' : 'fas fa-shopping-cart',
            color: 'color3 color3Text--text',
            click: this.useCart ? this.addToCart : this.onCompleteClick
          }
        }
      ]

      s = 0
      steps = steps.filter(f => !f.skip)
      steps.forEach(f => { f.value = s++ })

      return steps
    },
    userIsAdmin () {
      return this.access && this.access.td
    },
    division () {
      return this.tournament.divisions.find((d) => {
        return d.teams.find((t) => {
          return t.id === this.team.id
        })
      })
    },
    dropPlayer () {
      return this.team.players.find((p) => p.id === this.dropId)
    },
    order () {
      return this.$store.getters.order
    },
    credentials () {
      return {
        orderId: this.order ? this.order.id : null,
        email: this.order ? this.order.emailReceiptTo : null
      }
    },
    dto () {
      return {
        teamId: this.team.id,
        dropId: this.dropId,
        newPlayer: this.mode === 'drop' ? null : this.player.tournamentRegistrationDto,
        credentials: this.credentials
      }
    },
    currentStep () {
      return this.steps.find(f => f.value === this.window)
    },
    currentI () {
      return this.steps.findIndex(f => f.value === this.window)
    },
    nextStep () {
      return this.steps[this.currentI + 1]
    },
    previousStep () {
      return this.steps[this.currentI - 1]
    }
  },
  methods: {
    setMode (mode) {
      if (mode === 'change' && this.team.players.length === 0) mode = 'add'
      this.mode = mode
      this.reset()
    },
    onBackClick () {
      this.window = this.previousStep.value
    },
    onNextClick () {
      this.window = this.nextStep.value
    },
    onCompleteClick () {
      this.saving = true
      this.$VBL.team.change(this.dto, this.teamToken)
        .then((response) => {
          this.team.update(response.data)
          this.$emit('complete')
          this.autoSetMode()
        })
        .catch((error) => {
          console.log(error.response)
        })
        .finally(() => {
          this.saving = false
        })
    },
    addToCart () {
      var item = this.cartItem()
      console.log(item)
      this.$store.dispatch(actions.ADD_CART_ITEM, item)
      this.cartDialog = true
      this.autoSetMode()
    },
    cartItem () {
      const item = new CartItem()
      item.type = 'PA'
      item.organization = this.tournament.organization
      item.name = 'Player Registration'
      item.description = `Player addition | Player: ${this.player.fullname} | Team: ${this.team.name}`
      item.amount = this.entryFee.amount
      item.registration = this.registration.dto
      item.product = JSON.stringify({ teamId: this.team.id, player: this.player.tournamentRegistrationDto })
      item.division = this.registration.division.divisionsString
      item.teamName = this.registration._teamName
      item.preAuth = this.registration.window.canPayAtEvent
      item.cartFees.push(...this.tournament.getCartFees(this.registration.division.id))
      return item
    },
    reset () {
      this.currentStepOg = 1
      this.window = 0
      this.dropId = null
      this.newPlayer = new Player()
      this.registration = this.currentDivision ? this.tournament.newRegistration(this.currentDivision.id) : null
      this.busy = false
      this.cartAdd = false
      if (this.$refs.changeRequest) this.$refs.changeRequest.reset()
    },
    onChangeRequestValidChange (val) {
      this.validRequest = val
    },
    onSubmitRequest () {
      this.busy = true
      this.$refs.changeRequest.submitRequest()
        .then(response => {
          this.$emit('request-submitted')
        })
        .catch(error => {
          console.log(error.response)
        })
        .finally(() => {
          this.busy = false
        })
    },
    autoSetMode () {
      // if (!this.team.players || this.team.players.length < this.currentDivision.numOfPlayers) {
      //   this.setMode('add')
      // } else {
      //   this.setMode('change')
      // }
    }
  },
  watch: {
    dropId: function (n, o) {
      if (n) {
        if (this.division.registrationFields.requiredFields.includes('sameSchool')) {
          const field = this.team.registartionData.players[0].fields.find(f => {
            return f.label === 'High School'
          })
          this.newPlayer.highSchool = field.value
          this.player.highSchool = field.value
        }
      }
    },
    active: function (val) {
      this.autoSetMode()
    }
  },
  mounted () {
    this.autoSetMode()
  },
  components: {
    RegistrationFieldsReview,
    ChangeRequest,
    NewRegistrationFields,
    RefundPolicyDisplay
  }
}
</script>

<style>

</style>
